/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const countries = [
  "Afghanistan",
  "Argentina",
  "Australia",
  "Brazil",
  "Canada",
  "China",
  "Denmark",
  "Egypt",
  "France",
  "Germany",
  "Greece",
  "Hungary",
  "India",
  "Italy",
  "Japan",
  "Kenya",
  "Mexico",
  "Netherlands",
  "Norway",
  "Poland",
  "Portugal",
  "Russia",
  "South Africa",
  "Spain",
  "Sweden",
  "Switzerland",
  "Thailand",
  "Turkey",
  "United Kingdom",
  "United States",
];

export default function Transfers() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    // Show loader
    setIsLoading(true);

    // Fake a loading delay
    setTimeout(() => {
      // Hide loader
      setIsLoading(false);

      // Redirect to home page
      navigate("/"); // This replaces history.push('/') in v5
    }, 2000); // The delay is 2 seconds
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-12 sm:space-y-16">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Recipient Details
          </h2>
          {/* <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
            Please enter the payment details
          </p> */}

          {/* IBAN  */}
          <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label
                htmlFor="iban"
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              >
                IBAN
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  {/* <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">workcation.com/</span> */}
                  <input
                    type="text"
                    name="iban"
                    id="iban"
                    autoComplete="iban"
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="Enter IBAN"
                  />
                </div>
              </div>
            </div>

            {/* Beneficiary */}
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label
                htmlFor="iban"
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              >
                Beneficiary Name
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  {/* <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">workcation.com/</span> */}
                  <input
                    type="text"
                    name="beneficiary"
                    id="beneficiary"
                    autoComplete="beneficiary"
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="Beneficiary Name"
                  />
                </div>
              </div>
            </div>

            {/* Closing of section  */}
          </div>
        </div>

        {/* Payment Details Section  */}
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Payment Details
          </h2>

          <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
            {/* Amount and Currency Fields */}
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:py-6">
              {/* Amount Input Field */}
              <label
                htmlFor="amount"
                className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
              >
                Amount
              </label>
              <div className="mt-1 sm:col-span-1 sm:mt-0">
                <input
                  type="number"
                  name="amount"
                  id="amount"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="0.00"
                  step="0.01"
                />
              </div>

              {/* Currency Dropdown Field */}
              <div className="mt-1 sm:col-span-1 sm:mt-0">
                <select
                  id="currency"
                  name="currency"
                  autoComplete="currency-name"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                >
                  <option value="EUR">Euro (EUR)</option>
                  <option value="USD">United States Dollar (USD)</option>
                  <option value="JPY">Japanese Yen (JPY)</option>
                  <option value="GBP">British Pound Sterling (GBP)</option>
                  <option value="AUD">Australian Dollar (AUD)</option>
                  <option value="CAD">Canadian Dollar (CAD)</option>
                  <option value="CHF">Swiss Franc (CHF)</option>
                  <option value="CNY">Chinese Yuan Renminbi (CNY)</option>
                  <option value="SEK">Swedish Krona (SEK)</option>
                  <option value="NZD">New Zealand Dollar (NZD)</option>
                  {/* Add more currency options here */}
                </select>
              </div>
            </div>

            {/* Multiline text */}
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label
                htmlFor="reason"
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              >
                Message to Beneficiary
              </label>
              <div className="mt-2 sm:col-span-2 sm:mt-0">
                <textarea
                  id="reason"
                  name="reason"
                  rows={3}
                  className="block w-full max-w-2xl rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  defaultValue={""}
                />
                {/* <p className="mt-3 text-sm leading-6 text-gray-600">Describe the payment reason.</p> */}
              </div>
            </div>

            {/* Country */}
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
              <label
                htmlFor="country"
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
              >
                Country
              </label>
              <select
                id="country"
                name="country"
                autoComplete="country-name"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              >
                {countries.map((country) => (
                  <option key={country} value={country}>
                    {country}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Payment Execution
          </h2>
          {/* <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
            We'll always let you know about important changes, but you pick what else you want to hear about.
          </p> */}
          {/* 
          <div className="mt-10 space-y-10 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
            <fieldset>
              <legend className="sr-only">Priority</legend>
              <div className="sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4 sm:py-6">
                <div className="text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                  Priority
                </div>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <div className="max-w-lg">
                    <p className="text-sm leading-6 text-gray-600">These are delivered via SMS to your mobile phone.</p>
                    <div className="mt-6 space-y-6">
                      <div className="flex items-center gap-x-3">
                        <input
                          id="push-everything"
                          name="push-notifications"
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                        <label htmlFor="push-everything" className="block text-sm font-medium leading-6 text-gray-900">
                          Everything
                        </label>
                      </div>
                      <div className="flex items-center gap-x-3">
                        <input
                          id="push-email"
                          name="push-notifications"
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                        <label htmlFor="push-email" className="block text-sm font-medium leading-6 text-gray-900">
                          Same as email
                        </label>
                      </div>
                      <div className="flex items-center gap-x-3">
                        <input
                          id="push-nothing"
                          name="push-notifications"
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                        <label htmlFor="push-nothing" className="block text-sm font-medium leading-6 text-gray-900">
                          No push notifications
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div> */}
          <div className="mt-10 space-y-10 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
            <fieldset>
              <legend className="sr-only">Payment Priority</legend>
              <div className="sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4 sm:py-6">
                <div
                  className="text-sm font-semibold leading-6 text-gray-900"
                  aria-hidden="true"
                >
                  Payment Priority
                </div>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <div className="max-w-lg">
                    <div className="mt-6 space-y-6">
                      <div className="flex items-center gap-x-3">
                        <input
                          id="priority-normal"
                          name="payment-priority"
                          type="radio"
                          defaultChecked
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                        <label
                          htmlFor="priority-normal"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Normal
                        </label>
                      </div>
                      <div className="flex items-center gap-x-3">
                        <input
                          id="priority-immediate"
                          name="payment-priority"
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                        <label
                          htmlFor="priority-immediate"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Immediate Execution
                        </label>
                      </div>
                      <div className="flex items-center gap-x-3">
                        <input
                          id="priority-deferred"
                          name="payment-priority"
                          type="radio"
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                        <label
                          htmlFor="priority-deferred"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Deferred
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>

      {/* Process Payment Button */}
      <div className="mt-6 flex items-center justify-end gap-x-6">
        <button
          type="submit"
          className={`inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm ${
            isLoading ? "bg-gray-400" : "bg-indigo-600 hover:bg-indigo-500"
          } text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
          disabled={isLoading}
        >
          {isLoading ? "Processing..." : "Process Payment"}
        </button>
      </div>
    </form>
  );
}
