import React, { useState, useRef, useEffect, Fragment } from 'react';
import { Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { ADMIN_KEY, API_URL } from '../common/contants';

const VideoCapture: React.FC = () => {
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null
  );
  const [isRecording, setIsRecording] = useState(false);
  const [videoBlob, setVideoBlob] = useState<Blob | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const mediaStreamRef = useRef<MediaStream | null>(null);
  const [showPlayButton, setShowPlayButton] = useState(false);

  const [adminKey, setAdminKey] = useState<string>(
    ADMIN_KEY || localStorage.getItem('x-admin-api-key') || ''
  );

  // Save the admin key to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem('x-admin-api-key', adminKey);
  }, [adminKey]);

  useEffect(() => {
    const setupCamera = async () => {
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        // Store the stream in the ref for later use
        mediaStreamRef.current = mediaStream;

        if (videoRef.current) {
          videoRef.current.srcObject = mediaStream;
          videoRef.current.play().catch((error) => {
            console.error('Error auto-playing video', error);
          });
        }
      } catch (error) {
        console.error('Error accessing camera', error);
      }
    };

    setupCamera();

    // Cleanup function to turn off the camera when the component unmounts
    return () => {
      if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  const handleActionButtonClick = async () => {
    if (!isRecording) {
      startRecording();
    } else {
      stopRecording();
    }
  };

  const startRecording = async () => {
    setIsRecording(true);
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
        videoRef.current.play();
      }

      const recorder = new MediaRecorder(mediaStream);
      const chunks: BlobPart[] = [];

      recorder.ondataavailable = (e: BlobEvent) => chunks.push(e.data);
      recorder.onstop = async () => {
        const blob = new Blob(chunks, { type: 'video/mp4' });
        setVideoBlob(blob);

        if (videoRef.current) {
          videoRef.current.srcObject = null;
          videoRef.current.src = URL.createObjectURL(blob);
          videoRef.current.load(); // Remove the play() call
        }

        // Set state to show play button overlay
        // setShowPlayButton(true);
      };

      recorder.start();
      setMediaRecorder(recorder);
    } catch (error) {
      console.error('Error capturing video', error);
    }
  };

  const stopRecording = () => {
    mediaRecorder?.stop();
    // if (videoRef.current?.srcObject) {
    //   (videoRef.current.srcObject as MediaStream)
    //     .getTracks()
    //     .forEach((track) => track.stop());
    // }
    // setMediaRecorder(null); // Ensure the state is cleared
    setIsRecording(false);
  };

  const discardVideo = () => {
    // Clear the recorded video blob from state
    setVideoBlob(null);

    // Set the video element's srcObject back to the original camera stream
    if (videoRef.current && mediaStreamRef.current) {
      videoRef.current.srcObject = mediaStreamRef.current;
      videoRef.current.play();
    }
  };

  const sendVideo = async () => {
    if (!videoBlob) return;

    setLoading(true); // Start loading
    const formData = new FormData();
    formData.append('authVideo', videoBlob, 'video.mp4');

    try {
      const response = await fetch(
        `${API_URL}/external/face/authenticate/video?livenessOnly=true`,
        {
          method: 'POST',
          body: formData,
          headers: {
            'x-admin-api-key': adminKey, // Replace with your actual admin key value
          },
        }
      );

      const data = await response.json();
      console.log('Video uploaded', data);
      setToastMessage(JSON.stringify(data, null, 2)); // The '2' here adds indentation to the JSON string for readability
      setShowToast(true);
    } catch (error) {
      console.error('Error uploading video', error);
      setToastMessage('Error uploading video');
      setShowToast(true);
    } finally {
      setLoading(false); // Stop loading regardless of the outcome
    }
  };

  const clearPreview = () => {
    if (videoRef.current) {
      videoRef.current.src = '';
      URL.revokeObjectURL(videoRef.current.src);
    }
    setVideoBlob(null); // Also clear the videoBlob state
  };

  return (
    <div className="flex flex-col items-center justify-top h-screen bg-white-100 p-4">
      {/* Admin API Key Input */}
      <input
        type="text"
        value={adminKey}
        onChange={(e) => setAdminKey(e.target.value)}
        placeholder="Enter your x-admin-api-key"
        className="mb-4 p-2 border rounded w-full max-w-xs"
      />

      {/* Video Element and Play Button */}
      <div
        className="relative rounded-lg shadow-xl mb-4"
        style={{ width: '640px', height: '480px' }}
      >
        <video
          ref={videoRef}
          className="rounded-lg"
          controls={videoBlob !== null} // Show controls only if there's a video
          muted
          style={{ width: '100%', height: '100%' }} // Adjust as needed
        ></video>
        {showPlayButton && (
          <button
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black bg-opacity-50 rounded-full p-4"
            onClick={() => {
              if (videoRef.current) {
                videoRef.current.play();
                setShowPlayButton(false);
              }
            }}
          >
            {/* Replace with an actual play icon or image as needed */}
            <svg
              className="h-12 w-12 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M14.752 11.168l-4.552 2.634V8.534l4.552 2.634z"
              />
            </svg>
          </button>
        )}
      </div>

      {/* Action Buttons */}
      <div className="flex items-center gap-x-4">
        {!videoBlob ? (
          // This button toggles between starting and stopping recording based on isRecording state
          <button
            onClick={handleActionButtonClick}
            className={`flex items-center gap-x-1 rounded-md px-3 py-2 text-sm font-semibold shadow-sm ${
              isRecording
                ? 'bg-gray-300 hover:bg-gray-400'
                : 'bg-indigo-600 hover:bg-indigo-500'
            } ${
              isRecording ? 'text-black' : 'text-white'
            } focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${
              isRecording
                ? 'focus-visible:outline-gray-600'
                : 'focus-visible:outline-indigo-600'
            }`}
            disabled={loading}
          >
            {isRecording ? (
              <>
                <span className="relative flex h-3 w-3">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                  <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                </span>
                Stop Recording
              </>
            ) : (
              'Start Recording'
            )}
          </button>
        ) : (
          // These buttons appear after the video is recorded
          <>
            <button
              onClick={sendVideo}
              className="flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              disabled={loading}
            >
              {loading ? (
                <>
                  {/* Loader icon or animation */}
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 0116 0H4z"
                    ></path>
                  </svg>
                  Sending...
                </>
              ) : (
                'Send Video'
              )}
            </button>

            <button
              onClick={discardVideo}
              className="flex items-center gap-x-1 rounded-md bg-gray-300 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
              disabled={loading}
            >
              Discard
            </button>
          </>
        )}
      </div>

      {/* Global notification live region */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 z-50 flex items-end px-4 py-6 sm:items-start sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel */}
          <Transition
            show={showToast}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Video Upload Status
                    </p>
                    <p className="mt-1 text-sm text-gray-500">{toastMessage}</p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        setShowToast(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </div>
  );
};

export default VideoCapture;
