import React, { useState } from 'react';
import CryptoJS from 'crypto-js';
import logo from '../assets/logo.svg';
import {
  API_URL,
  EXTERNAL_USER_ID,
  ORG_ID,
  SIGNING_KEY,
} from '../common/contants';

declare global {
  interface Window {
    BehavioralAuthSDK: BehavioralAuthSDKType;
  }
}

interface BehavioralAuthSDKType {
  new (config: any): BehavioralAuthSDKInstance;
}

export interface BehavioralAuthSDKInstance {
  start: () => void;
  stop: () => void;
  // Add other methods or properties if required
}

interface StartSDKProps {
  setClientInstance: (client: BehavioralAuthSDKInstance) => void;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  updateUserId: React.Dispatch<React.SetStateAction<string | null>>;
}

const handleResetSessions = () => {
  localStorage.clear();
  console.log('Session info have been reset');
};

const StartSDK: React.FC<StartSDKProps> = ({
  setClientInstance,
  isModalOpen,
  updateUserId,
  setIsModalOpen,
}) => {
  const [localUserId, setLocalUserId] = useState<string>(EXTERNAL_USER_ID);
  const [useWebcam, setUseWebcam] = useState<boolean>(false);

  const handleUserIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newUserId = e.target.value;
    setLocalUserId(newUserId);
    updateUserId(newUserId); // Use the renamed prop here
  };

  const handleWebcamChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUseWebcam(e.target.checked); // Update the useWebcam state
  };

  const startSDK = () => {
    const token = createJWT(
      {
        alg: 'HS256',
        typ: 'JWT',
      },
      {
        orgId: ORG_ID,
        userId: localUserId,
        exp: Math.floor(Date.now() / 1000) + 60 * 60 * 24, // 24 hours
      },
      SIGNING_KEY
    );

    const client = new window.BehavioralAuthSDK({
      apiKey: token,
      serviceUrl: API_URL,
      userId: localUserId,
      lockScreenOnAuthLost: false,
      lockScreenOnFaceLost: false,
      useWebcam: useWebcam,
      useLocation: true,
      verbose: true,
      rum: true,
    });

    setClientInstance(client); // <-- Use the function passed as a prop

    client.start();
    console.log('SDK started with user: ' + localUserId);
    setIsModalOpen(false); // Close the modal after starting the SDK
  };

  console.log({
    EXTERNAL_USER_ID,
    ORG_ID,
    SIGNING_KEY,
    API_URL,
  });

  return (
    <>
      {isModalOpen && (
        <div className="modal-backdrop">
          {/* <div className="modal"> */}
          {/* <div className="modal-content"> */}
          {/* <h1> hello </h1> */}
          <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
              <img className="mx-auto h-10 w-auto" src={logo} alt="Quadible" />
              <h2 className="mt-5 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Sign in to your account
              </h2>
            </div>

            <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
              <form className="space-y-6" action="#" method="POST">
                <div>
                  {/* <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Username
                  </label> */}
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      value={localUserId}
                      onChange={handleUserIdChange}
                      placeholder="Enter your username"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div>
                  <button
                    onClick={startSDK}
                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Sign in
                  </button>
                  <label className="inline-flex items-center mt-3">
                    <input
                      type="checkbox"
                      className="form-checkbox h-5 w-5 text-gray-600"
                      checked={useWebcam}
                      onChange={handleWebcamChange}
                    />
                    <span className="ml-2 text-gray-700">Use webcam</span>
                  </label>
                </div>
              </form>
              <p className="mt-10 text-center text-sm text-gray-500">
                Want a new session for same user?{' '}
                <button
                  onClick={handleResetSessions}
                  className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
                  type="button" // Specify the button type to prevent form submission
                >
                  Clear Session Info
                </button>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

function createJWT(header: object, payload: object, secret: string): string {
  // Encode header
  const encodedHeader = base64UrlEncode(JSON.stringify(header));

  // Encode payload
  const encodedPayload = base64UrlEncode(JSON.stringify(payload));

  // Create signature
  let signature = encodedHeader + '.' + encodedPayload;
  signature = CryptoJS.HmacSHA256(signature, secret).toString(
    CryptoJS.enc.Base64
  );
  signature = signature
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');

  // Return complete JWT
  return encodedHeader + '.' + encodedPayload + '.' + signature;
}

function base64UrlEncode(str: string): string {
  let encoded = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(str));
  encoded = encoded.replace('+', '-').replace('/', '_').replace(/=+$/, '');
  return encoded;
}

export default StartSDK;
