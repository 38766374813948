import React, { useState } from "react";
import "./App.css";
import StartSDK, { BehavioralAuthSDKInstance } from "./components/StartSDK";
import BankingUI from "./components/BankingUI";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  const [clientInstance, setClientInstance] =
    useState<BehavioralAuthSDKInstance | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [userId, setUserId] = useState<string | null>(null);

  const stopSDK = () => {
    if (clientInstance) {
      clientInstance.stop();
      setIsModalOpen(true); // Open the modal
      console.log("SDK stopped");
    }
  };

  return (
    <div className="App">
      <StartSDK
        setClientInstance={setClientInstance}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        updateUserId={setUserId}
      />
      {/* <button onClick={stopSDK}>Stop</button> */}
      <Router>
        <BankingUI userId={userId} stopSDK={stopSDK} />
      </Router>
    </div>
  );
}

export default App;
